const footerMenuList = [
  // {
  //   id: 1,
  //   title: "About Us",
  //   diffClass: "",
  //   list: [
  //     {
  //       id: 1,
  //       href: "/about-us",
  //       text: "The Team",
  //     },
  //     {
  //       id: 2,
  //       href: "/blog",
  //       text: "Blog",
  //     },
  //   ],
  // },
  {
    id: 3,
    title: "Menu",
    diffClass: "",
    list: [
      {
        id: 1,
        text: "Home",
        href: "/",
      },
      {
        id: 2,
        text: "Sponsor Us",
        href: "/sponsor-us",
      },
      {
        id: 3,
        text: "Community",
        href: "/join-the-community",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/SoftwareTalks",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/SoftwareTalksIO",
    text: "twitter",
  },
];

export { footerMenuList, socialIcons };
